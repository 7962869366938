.custom-modal {
    .modal-dialog {
        flex: 1;
        min-height: calc(100% - 60px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal-content {
        border-radius: 0;
        border: 0;
        button.close {
            font-family: sans-serif;
            font-weight: 300;
            font-size: rem-calc(37);
            color: #fff;
            position: absolute;
            bottom: calc(100% + 3px);
            left: calc(100% + 3px);
            opacity: 1;
            text-shadow: none;
            span {
                z-index: 1;
            }
            &:hover {
                color: $color-orange;
            }
        }
    }
}
.breadcrumbs {
    padding-top: rem-calc(35);
    padding-bottom: rem-calc(0);
    * {
        font-size: rem-calc(14);
    }
    &-link {
        color: #000;
        display: inline-block;
        position: relative;
        margin-right: rem-calc(20);
        &::after {
            content: '/';
            position: absolute;
            right: -15px;
            top: 0;
            pointer-events: none;
        }
        &:hover {
            color: $color-brown;
            text-decoration: none;
            &::after {
                color: #000;
            }
        }
    }
}