@charset "UTF-8";
/* #region Fonts */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Rubik:wght@300;400;500;700&display=swap");
/* #endregion */
/* #region Variables */
/* #endregion */
/* #region Function */
/* #endregion */
/* #region Global */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

body {
  font-family: 'Rubik', sans-serif;
  color: #00313C;
  overflow-x: hidden;
  position: relative; }

* {
  outline: none !important; }

/* #endregion */
/* #region Typography */
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: 'Playfair Display', serif; }

.h1, h1 {
  font-size: 3.4375rem;
  font-weight: 900; }

.h2, h2 {
  font-size: 2.375rem;
  margin-bottom: 1.875rem;
  font-weight: 700; }

.h3, h3 {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 1.25rem; }

.h4, h4 {
  font-weight: 700;
  line-height: 1.33334;
  margin-bottom: 1.125rem; }

.h5, h5 {
  font-weight: 700; }

p {
  margin-bottom: 1.5rem; }

.font-weight-medium {
  font-weight: 500; }

ul li, ol li {
  font-size: 1rem; }

[type=button], [type=submit], button {
  -webkit-appearance: none; }

/* #endregion */
.bg-darkder {
  background-color: #00313C; }

.bg-pastel {
  background-color: #EAA794; }

.bg-pink {
  background-color: #ABC7C9; }

.bg-blue {
  background-color: #00313C; }

.bg-brown {
  background-color: #D1CCBD; }

.bg-green {
  background-color: #96A480; }

.text-white, .articles-masonry-item.blue .article-content *, .articles-masonry-item.green .article-content * {
  color: #fff; }

.text-darker, .articles-masonry-item.darker .article-content * {
  color: #00313C; }

.text-pastel {
  color: #EAA794; }

.text-pink {
  color: #EAA794; }

.text-blue {
  color: #ABC7C9; }

.text-brown, .articles-masonry-item.brown .article-content * {
  color: #D1CCBD; }

.text-green {
  color: #96A480; }

.text-red {
  color: #FF0000; }

/* #region Layout */
.row:not(.no-gutters) {
  margin-left: -10px;
  margin-right: -10px; }
  .row:not(.no-gutters) .col, .row:not(.no-gutters) > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.section {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  .section-border {
    border-bottom: solid 1px rgba(209, 204, 189, 0.5); }
  .section .section {
    padding-top: 0;
    padding-bottom: 0; }

.wave-bg {
  position: relative; }
  .wave-bg .waving {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 100%;
    z-index: -1; }

.grey-bg {
  background-color: #eee; }

.brown-bg {
  background-color: #f6f5f2; }

.section.darker-bg .feature-item p {
  color: #fff; }

.virtual-holder .grey-bg, .virtual-holder .brown-bg, .virtual-holder .section.darker-bg {
  background-color: #fff; }
  .virtual-holder .grey-bg .intro-wrap h4, .virtual-holder .grey-bg .intro-wrap small, .virtual-holder .brown-bg .intro-wrap h4, .virtual-holder .brown-bg .intro-wrap small, .virtual-holder .section.darker-bg .intro-wrap h4, .virtual-holder .section.darker-bg .intro-wrap small {
    color: #96a480; }
  .virtual-holder .grey-bg h2, .virtual-holder .grey-bg p, .virtual-holder .grey-bg span, .virtual-holder .brown-bg h2, .virtual-holder .brown-bg p, .virtual-holder .brown-bg span, .virtual-holder .section.darker-bg h2, .virtual-holder .section.darker-bg p, .virtual-holder .section.darker-bg span {
    color: #00313c; }

/* #endregion */
/* #region Form */
.btn {
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 10rem;
  padding: 1rem 1.875rem;
  box-shadow: none;
  text-transform: uppercase; }
  .btn.focus, .btn:focus {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); }
  .btn-primary {
    background-color: #789D4A;
    border-color: #789D4A; }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: #6b8c42;
      border-color: #6b8c42; }
  .btn-secondary {
    background-color: #ABC7C9;
    border-color: #ABC7C9;
    color: #00313C; }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: #9bbdbf;
      border-color: #9bbdbf;
      color: #00313C; }
  .btn-outline {
    padding: 0.9375rem 2rem;
    background: transparent;
    border: solid 3px #789D4A;
    color: #789D4A; }
    .btn-outline:hover, .btn-outline:focus {
      color: #fff;
      background: #789D4A !important; }
  .btn-w160 {
    min-width: 10rem; }

.custom-control-label::before {
  background-color: #F7F7F8;
  border-color: rgba(0, 49, 60, 0.4); }

.custom-control-label .label-selected {
  display: none; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00313C;
  border-color: #00313C; }

.custom-control-input:checked ~ .custom-control-label .label-selected {
  display: inline; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem; }

.dropdown-toggle::after {
  margin-left: 0.5rem;
  border: 0;
  width: 0.75rem;
  height: 0.5rem;
  background: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%2300313C'%2F%3E%3C%2Fsvg%3E") no-repeat;
  background-size: 0.75rem 0.5rem;
  vertical-align: 0.0625rem;
  transition: transform .2s ease; }

.dropdown.show .dropdown-toggle::after {
  transform: rotateX(-180deg); }

.dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); }

.dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    background: #789D4A; }
  .dropdown-item.active, .dropdown-item:active {
    color: #789D4A !important; }

.bootstrap-select {
  width: 100% !important;
  display: block !important; }
  .bootstrap-select > .dropdown-toggle {
    background-color: transparent;
    border-color: #00313C;
    box-shadow: none;
    font-weight: 600; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
      color: #00313C; }
    .bootstrap-select > .dropdown-toggle::after {
      margin-top: 0;
      width: 0.875rem; }
  .bootstrap-select .dropdown-menu li a:hover {
    color: #fff; }

.bootstrap-tagsinput {
  display: block;
  padding: 0.4375rem 0 0.375rem;
  color: #00313C;
  border-bottom: 1px solid rgba(0, 49, 60, 0.4);
  width: 100%;
  cursor: text; }
  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    font-size: 1rem;
    font-weight: 600;
    height: 2.25rem;
    color: #00313C; }
    .bootstrap-tagsinput input::placeholder {
      color: #00313C; }
    .bootstrap-tagsinput input:focus {
      border: none;
      box-shadow: none; }
  .bootstrap-tagsinput .tag {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.125rem;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.42857;
    padding: 0.5rem 0.625rem;
    color: #00313C; }
    .bootstrap-tagsinput .tag [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;
      vertical-align: -2px;
      display: inline-block; }
      .bootstrap-tagsinput .tag [data-role="remove"]:after {
        display: block;
        content: "×";
        padding: 0px 2px;
        font-size: 1.25rem;
        line-height: 1rem; }

/* #endregion */
/* #region Navbar */
.navtop-search {
  position: relative;
  margin-left: 0.9375rem;
  display: flex;
  align-items: center;
  margin-right: 0.625rem; }
  .navtop-search .form-control {
    position: absolute;
    right: -0.625rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    height: 2.5rem;
    border-radius: 0.125rem;
    border: 0;
    width: 0;
    font-size: 0.75rem;
    line-height: 1.538462;
    font-weight: 500;
    transition: all .3s ease;
    box-shadow: none; }
  .navtop-search .btn-search {
    position: relative;
    z-index: 10;
    display: block;
    padding: 0.09375rem;
    background-color: transparent;
    border: 0;
    color: #fff; }
    .navtop-search .btn-search:hover {
      color: #789D4A; }
    .navtop-search .btn-search svg {
      display: block; }
  .navtop-search.active .form-control {
    width: 250px;
    padding: 0.3125rem 2.25rem 0.3125rem 0.75rem;
    background: #f1f2f3; }
  .navtop-search.active .btn-search {
    color: #00313C; }

.navbar {
  padding: 1.875rem 2.5rem;
  transition: all .15s linear; }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    margin-right: 1.5rem; }
    .navbar-brand:hover {
      color: #fff; }
    .navbar-brand svg {
      display: block;
      transition: color .2s ease; }
    .navbar-brand img {
      width: 180px; }
      @media (max-width: 575px) {
        .navbar-brand img {
          width: 110px; } }
      .navbar-brand img.scroll {
        display: none; }
  .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 0.9375rem;
    font-weight: 500;
    padding: 0.625rem 0.875rem;
    text-transform: uppercase; }
    .navbar-nav .nav-item .nav-link:hover {
      color: #789D4A; }
  .navbar-nav .nav-item.active .nav-link {
    color: #789D4A; }
  .navbar-nav .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E"); }
  .navbar-nav .dropdown-toggle:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%23DC582A'%2F%3E%3C%2Fsvg%3E"); }
  .navbar-nav .dropdown-item {
    font-size: 0.9375rem;
    font-weight: 500;
    padding: 0.5rem 0.9375rem; }
  .navbar.scrolled, .navbar.navbar-white {
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 40px; }
    .navbar.scrolled .navbar-nav .nav-item .nav-link, .navbar.navbar-white .navbar-nav .nav-item .nav-link {
      color: #00313C; }
      .navbar.scrolled .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-white .navbar-nav .nav-item .nav-link:hover {
        color: #789D4A; }
    .navbar.scrolled .navbar-nav .nav-item.active .nav-link, .navbar.navbar-white .navbar-nav .nav-item.active .nav-link {
      color: #789D4A; }
    .navbar.scrolled .navbar-nav .dropdown-toggle::after, .navbar.navbar-white .navbar-nav .dropdown-toggle::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.582.043L.043.582 4.73 5.27l.27.257.27-.257L9.957.582 9.418.043 5 4.461.582.043z' fill='%2300313C'/%3E%3C/svg%3E"); }
    .navbar.scrolled .navbar-nav .dropdown-toggle:hover::after, .navbar.navbar-white .navbar-nav .dropdown-toggle:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%23DC582A'%2F%3E%3C%2Fsvg%3E"); }
    .navbar.scrolled .navbar-brand, .navbar.navbar-white .navbar-brand {
      color: #00313C; }
      .navbar.scrolled .navbar-brand img.no-scroll, .navbar.navbar-white .navbar-brand img.no-scroll {
        display: none; }
      .navbar.scrolled .navbar-brand img.scroll, .navbar.navbar-white .navbar-brand img.scroll {
        display: block; }
    .navbar.scrolled .hamburger-inner, .navbar.scrolled .hamburger-inner::before, .navbar.scrolled .hamburger-inner::after, .navbar.navbar-white .hamburger-inner, .navbar.navbar-white .hamburger-inner::before, .navbar.navbar-white .hamburger-inner::after {
      background-color: #00313C; }
    .navbar.scrolled .navtop-search .btn-search:hover, .navbar.navbar-white .navtop-search .btn-search:hover {
      color: #ABC7C9; }
    .navbar.scrolled .contact-wrap .btn-tel:hover, .navbar.navbar-white .contact-wrap .btn-tel:hover {
      color: #ABC7C9; }

.contact-wrap {
  display: flex;
  align-items: center;
  justify-content: center; }
  .contact-wrap .btn-tel {
    color: #fff;
    font-size: 0.9375rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-decoration: none; }
    .contact-wrap .btn-tel svg {
      margin-right: 0.3125rem; }
    .contact-wrap .btn-tel:hover {
      color: #789D4A; }

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 -0.875rem 0 0.5rem;
  overflow: visible;
  display: none;
  padding: 0.9375rem 0.875rem; }
  .hamburger-box {
    width: 22px;
    height: 20px;
    display: block;
    position: relative; }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 22px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -6px;
      transition: top 0.075s 0.12s ease,opacity 0.075s ease; }
    .hamburger-inner::after {
      bottom: -6px;
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
    .hamburger.is-active .hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  z-index: 1040;
  display: none;
  overflow: hidden;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  background-color: #fff; }
  .navigation-open .navigation {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s; }
  .navigation-header {
    padding: 1rem 1.5rem; }
  .navigation .close {
    display: block;
    float: none;
    color: #00313C;
    font-family: sans-serif;
    font-size: 2.5rem;
    line-height: 1;
    margin-left: auto;
    transition: all .1s ease;
    opacity: 1 !important;
    font-weight: 300; }
  .navigation-body {
    overflow: hidden;
    position: relative;
    z-index: 2; }
  .navigation-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1034;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease; }
    .navigation-open .navigation-backdrop {
      opacity: 1;
      visibility: visible; }
  .navigation .nav-link {
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.538462;
    font-weight: 500;
    padding: 0.625rem 1.5rem;
    color: #00313C; }
    .navigation .nav-link[data-toggle="collapse"] {
      padding-right: 2.5rem; }
  .navigation .collapse-body .nav-link {
    padding-left: 2.25rem; }

.navigation-open {
  overflow: hidden !important;
  height: 100% !important; }

[data-toggle="collapse"] {
  position: relative; }
  [data-toggle="collapse"] .icon-chevron-down {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    transition: transform .2s ease; }
  [data-toggle="collapse"]:not(.collapsed) .icon-chevron-down {
    transform: rotateX(-180deg); }

.navbar.scrolled .navtop-search .btn-search,
.navbar.scrolled .contact-wrap .btn-tel,
.navbar.navbar-white .navtop-search .btn-search,
.navbar.navbar-white .contact-wrap .btn-tel {
  color: #00313C; }

/* #endregion */
/* #region Big hero */
.big-header {
  display: flex;
  height: 650px;
  position: relative; }
  .big-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 185px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 2; }
  .big-header-carousel {
    width: 65%; }
    .big-header-carousel .header-carousel {
      height: 100%; }
      .big-header-carousel .header-carousel .owl-stage-outer, .big-header-carousel .header-carousel .owl-stage, .big-header-carousel .header-carousel .owl-item, .big-header-carousel .header-carousel .owl-item-inner {
        height: 100%; }
      .big-header-carousel .header-carousel .owl-item-inner {
        position: relative;
        overflow: hidden;
        display: flex; }
        .big-header-carousel .header-carousel .owl-item-inner img {
          width: 100%;
          min-height: 100%;
          object-fit: cover; }
      .big-header-carousel .header-carousel .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 50px; }
        .big-header-carousel .header-carousel .owl-dots .owl-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: rgba(0, 49, 60, 0.4);
          margin: 0.625rem;
          transition: background-color .2s ease-out; }
          .big-header-carousel .header-carousel .owl-dots .owl-dot.active, .big-header-carousel .header-carousel .owl-dots .owl-dot:hover {
            background: #00313C; }
  .big-header-content {
    background: #00313C;
    width: 35%;
    color: #fff;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 3; }
    .big-header-content p {
      margin-top: 7.5rem;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1.875rem; }
    .big-header-content .link {
      color: #789D4A;
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 0.9375rem;
      text-decoration: none; }
  .big-header .h1 {
    z-index: 4;
    font-size: 3.75rem;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    color: #00313C; }
    .big-header .h1.title-js {
      position: absolute;
      top: calc(50% - 90px);
      right: 35%; }
      .big-header .h1.title-js span:last-child {
        color: #fff; }
  .big-header .quick-access {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: #D1CCBD;
    color: #00313C;
    padding: 1.875rem;
    width: 240px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); }
    .big-header .quick-access span {
      font-size: 1.125rem;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 0.875rem; }
    .big-header .quick-access ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .big-header .quick-access ul li {
        border-bottom: solid 1px rgba(0, 49, 60, 0.2); }
        .big-header .quick-access ul li a {
          color: #00313C;
          text-decoration: none;
          font-size: 0.9375rem;
          font-weight: 500;
          text-transform: uppercase;
          display: block;
          padding: 0.625rem 0;
          width: 100%;
          position: relative;
          transition: color .2s ease-out; }
          .big-header .quick-access ul li a::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transition: all .2s ease-out;
            transform: translate(-10px, -50%);
            opacity: 0;
            width: 18px;
            height: 10px;
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#DC582A'%2F%3E%3C%2Fsvg%3E") no-repeat; }
          .big-header .quick-access ul li a:hover, .big-header .quick-access ul li a:focus {
            color: #789D4A; }
            .big-header .quick-access ul li a:hover::after, .big-header .quick-access ul li a:focus::after {
              opacity: 1;
              transform: translate(0, -50%); }
        .big-header .quick-access ul li:last-child {
          border-bottom: 0;
          padding-bottom: 0; }

/* #endregion */
/* #region Footer */
.subscribe {
  background: #00313C;
  color: #fff;
  padding: 1.625rem 0; }
  .subscribe-inner {
    display: flex;
    align-items: center;
    justify-content: center; }
    .subscribe-inner p {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: uppercase; }
    .subscribe-inner input {
      margin-left: 1.875rem;
      width: 100%;
      max-width: 285px;
      background: transparent;
      color: #ABC7C9;
      font-size: 1.5rem;
      font-weight: 500;
      border: none;
      border-bottom: solid 1px #ABC7C9;
      border-radius: 0 !important; }
      .subscribe-inner input::placeholder {
        color: #ABC7C9; }
      .subscribe-inner input:focus {
        background: transparent;
        color: #ABC7C9; }

.footer {
  background: #D1CCBD; }
  .footer-top {
    padding: 5rem 0;
    border-bottom: solid 1px rgba(0, 49, 60, 0.2); }
    .footer-top p {
      font-size: 1rem;
      margin-bottom: 0.3125rem; }
      .footer-top p span {
        display: inline-block;
        color: #789D4A;
        font-weight: 700;
        margin-right: 0.3125rem; }
    .footer-top .footer-address a {
      color: #01313c;
      text-decoration: underline; }
    .footer-top .link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #00313C;
      font-weight: 500;
      font-size: 1.125rem;
      text-decoration: none;
      margin-bottom: 1.25rem; }
      .footer-top .link:hover, .footer-top .link:focus {
        color: #789D4A; }
  .footer-logo {
    display: inline-block;
    color: #00313C;
    margin-bottom: 1.875rem; }
    .footer-logo:hover {
      color: #00313C; }
  .footer-list {
    margin: 0;
    margin-bottom: 1.5625rem;
    padding: 0;
    list-style: none; }
    .footer-list li {
      margin-bottom: 0.9375rem; }
      .footer-list li a {
        color: #00313C;
        font-size: 0.9375rem;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none; }
        .footer-list li a:hover, .footer-list li a:focus {
          color: #789D4A; }
  .footer-bottom {
    padding: 1.625rem 0; }
    .footer-bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .footer-bottom-inner p {
        margin: 0;
        font-size: 0.875rem; }
      .footer-bottom-inner a {
        color: inherit;
        position: relative;
        text-decoration: none; }
        .footer-bottom-inner a::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          background: #00313C; }
  .footer .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .footer .socials {
    margin-top: 1.875rem;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .footer .socials a {
      margin: 5px;
      color: #00313C; }
      .footer .socials a:hover {
        color: #789D4A; }
  .footer .sm-text {
    margin-top: 1.25rem;
    font-size: 0.875rem; }
    .footer .sm-text a {
      color: inherit;
      text-decoration: underline; }

/* #endregion */
/* #region Intro */
.intro-wrap {
  margin-bottom: 0.9375rem;
  position: relative;
  z-index: 1; }
  .intro-wrap.center {
    text-align: center; }
  .intro-wrap small {
    font-size: 1.125rem;
    font-weight: 700;
    color: #ABC7C9;
    text-transform: uppercase;
    margin-bottom: 0.625rem; }
  .intro-wrap h2 {
    font-size: 2.8125rem;
    font-weight: 400;
    margin-bottom: 1.25rem; }
  .intro-wrap p {
    display: inline-block;
    max-width: 600px;
    font-size: 1.0625rem;
    margin-bottom: 1.5625rem; }
  .intro-wrap .btn-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% + 15px);
    margin-left: -15px; }
    .intro-wrap .btn-wrap a {
      margin: 5px 15px; }
      .intro-wrap .btn-wrap a.link {
        font-size: 1.125rem;
        font-weight: 500;
        text-decoration: none;
        color: #00313C; }

.cta-wrap {
  text-align: center;
  padding-top: 1.875rem; }

/* #endregion */
/* #region Text w Image */
.has-bg {
  background-repeat: no-repeat;
  background-position-y: 65px;
  background-size: 65% auto; }
  .has-bg.right {
    background-position-x: 100%; }
  .has-bg.left {
    background-position-x: 0; }

.textside {
  background: #fff;
  padding: 2.5rem;
  margin-top: 3.125rem;
  position: relative;
  z-index: 1;
  margin-bottom: 1.875rem; }
  .textside p {
    font-size: 1rem;
    margin-bottom: 1.5625rem; }
  .textside ul {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5625rem;
    list-style: none; }
    .textside ul li {
      padding-left: 1.875rem;
      position: relative;
      margin-bottom: 0.625rem; }
      .textside ul li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
  .textside *:last-child {
    margin-bottom: 0; }

.imgside {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }
  .imgside img {
    width: 100%;
    height: auto; }

/* #endregion */
/* #region Map */
.content-map {
  width: auto;
  padding-left: 4.0625rem;
  margin-top: 1.875rem;
  padding-right: 4.0625rem;
  position: relative; }
  .content-map-inner {
    width: 100%;
    height: 650px; }
  .content-map-filter {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 240px;
    background: #D1CCBD;
    padding: 1.875rem;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); }
    .content-map-filter span {
      font-size: 1.125rem;
      display: inline-block;
      margin-bottom: 1.25rem; }
    .content-map-filter .custom-checkbox {
      margin-right: 0 !important;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      border-bottom: solid 1px rgba(0, 49, 60, 0.2); }
      .content-map-filter .custom-checkbox:last-child {
        border-bottom: 0; }
      .content-map-filter .custom-checkbox .custom-control-label {
        font-size: 0.9375rem;
        font-weight: 500;
        text-transform: uppercase; }
      .content-map-filter .custom-checkbox .custom-control-input:checked ~ .custom-control-label {
        color: #fff; }
      .content-map-filter .custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        background-color: #00313C;
        border-color: #00313C; }
      .content-map-filter .custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #00313C;
        box-shadow: 0 0 0 0.2rem rgba(0, 49, 60, 0.25); }
      .content-map-filter .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 49, 60, 0.25); }
      .content-map-filter .custom-checkbox .custom-control-label:before {
        border-radius: 0; }

.mapItem {
  background: #F6F5F2; }
  .mapItem-img {
    position: relative;
    width: 100%;
    padding-top: 45%;
    overflow: hidden; }
    .mapItem-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover; }
  .mapItem-content {
    padding: 1.25rem 1.25rem; }
    .mapItem-content h3 {
      font-family: 'Rubik', sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.9375rem; }
    .mapItem-content p {
      margin-bottom: 1.25rem;
      font-size: 1rem; }
    .mapItem-content ul {
      margin: 0;
      margin-bottom: 0.625rem;
      padding: 0;
      list-style: none;
      column-count: 2;
      column-gap: 20px; }
      .mapItem-content ul li {
        padding-left: 25px;
        position: relative;
        margin-bottom: 0.625rem;
        font-size: 1rem; }
        .mapItem-content ul li::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
    .mapItem-content .btn-wrap {
      margin-left: -10px;
      width: calc(100% + 20px); }
      .mapItem-content .btn-wrap a {
        margin: 0.3125rem 0.625rem; }
        .mapItem-content .btn-wrap a.link {
          font-size: 1.125rem;
          font-weight: 500;
          color: #00313C;
          text-decoration: none; }

.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0; }

.gm-style .gm-style-iw-d {
  overflow: auto !important; }

.gm-style .gm-style-iw-a button[aria-label="Close"] {
  left: 0 !important;
  top: 0 !important; }
  .gm-style .gm-style-iw-a button[aria-label="Close"] img {
    width: 22px !important;
    height: 22px !important; }

.gm-style .gm-style-iw-t::after {
  display: none; }

.filter-dropdown .dropdown-toggle {
  border-radius: 0;
  border: 0;
  background: rgba(255, 255, 255, 0.4);
  padding: 0.8125rem;
  font-size: 0.9375rem;
  font-weight: 500; }
  .filter-dropdown .dropdown-toggle:focus {
    outline: none !important; }

.filter-dropdown .dropdown-menu li a {
  padding: 0.375rem 0.75rem; }
  .filter-dropdown .dropdown-menu li a.active, .filter-dropdown .dropdown-menu li a:hover, .filter-dropdown .dropdown-menu li a:active {
    background: #789D4A;
    color: #fff !important; }
  .filter-dropdown .dropdown-menu li a span.text {
    margin-bottom: 0;
    font-size: 0.9375rem;
    text-transform: uppercase; }

/* #endregion */
/* #region Masonry */
.articles-masonry {
  margin-left: 0;
  margin-right: -15px;
  padding-top: 1.875rem;
  margin-bottom: 1.25rem; }

.articles-masonry-item {
  margin: 0 auto 0.9375rem;
  display: block;
  width: calc(33.33333% - 15px); }
  .articles-masonry-item:hover .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#DC582A'%2F%3E%3C%2Fsvg%3E") no-repeat;
    background-position: center; }
  .articles-masonry-item:hover .article-content {
    color: #789D4A; }
  .articles-masonry-item.no-image .article-wrap {
    min-height: 350px;
    background-color: #D1CCBD; }
    .articles-masonry-item.no-image .article-wrap::before {
      display: none; }
    .articles-masonry-item.no-image .article-wrap::after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#00313c'%2F%3E%3C%2Fsvg%3E") no-repeat;
      background-position: center; }
    .articles-masonry-item.no-image .article-wrap .article-content {
      color: #00313C; }

.article-wrap {
  position: relative; }
  .article-wrap::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 0; }
  .article-wrap::after {
    content: '';
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 24px;
    height: 24px;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#fff'%2F%3E%3C%2Fsvg%3E") no-repeat;
    background-position: center; }
  .article-wrap img {
    width: 100%; }

.article-content {
  position: absolute;
  z-index: 1;
  bottom: 1.875rem;
  left: 1.875rem;
  text-decoration: none;
  color: #fff;
  max-width: calc(100% - 100px); }
  .article-content *:last-child {
    margin-bottom: 0; }
  .article-content small {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.3125rem; }
  .article-content h3 {
    margin-bottom: 0.625rem;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Rubik', sans-serif; }
  .article-content p {
    font-size: 1rem;
    margin-bottom: 0; }

.article-message {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 0.4375rem; }
  .article-message span {
    font-size: 0.8125rem;
    font-weight: 500; }

/* #endregion */
/* #region Filters */
.filter-bar {
  background: #D1CCBD;
  padding: 1.25rem; }
  .filter-bar span {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
    display: inline-block; }

/* #endregion */
/* #region Home-item */
.home-carousel-wrap {
  margin-top: 2.5rem;
  position: relative; }
  .home-carousel-wrap .home-carousel,
  .home-carousel-wrap .home-carousel-two {
    position: static; }
  .home-carousel-wrap .owl-nav .owl-prev,
  .home-carousel-wrap .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background-color: #ABC7C9 !important;
    display: flex;
    align-items: center;
    justify-content: center; }
    .home-carousel-wrap .owl-nav .owl-prev.disabled,
    .home-carousel-wrap .owl-nav .owl-next.disabled {
      opacity: .4; }
    .home-carousel-wrap .owl-nav .owl-prev span,
    .home-carousel-wrap .owl-nav .owl-next span {
      font-size: 2.25rem;
      font-family: 'Rubik';
      font-weight: 300;
      color: #fff; }
  .home-carousel-wrap .owl-nav .owl-prev {
    left: 0; }
  .home-carousel-wrap .owl-nav .owl-next {
    right: 0; }

.homeItem {
  background: #F6F5F2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.875rem;
  position: relative; }
  .homeItem > span {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background: #00313C;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9375rem; }
  .homeItem-img {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    display: block;
    overflow: hidden; }
    .homeItem-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%); }
  .homeItem-content {
    padding: 1.25rem; }
  .homeItem-features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.875rem; }
    .homeItem-features span {
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .homeItem-features span svg {
        color: #ABC7C9; }
      .homeItem-features span img {
        width: auto !important;
        margin-right: 0.3125rem; }
  .homeItem-inner h3 {
    font-family: 'Rubik', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.9375rem; }
    .homeItem-inner h3 a {
      text-decoration: none;
      color: #00313C; }
      .homeItem-inner h3 a:hover {
        text-decoration: none; }
  .homeItem-inner p {
    font-size: 1rem;
    margin-bottom: 1.875rem; }
  .homeItem-inner ul {
    margin-bottom: 1.875rem;
    padding: 0;
    list-style: none; }
    .homeItem-inner ul li {
      padding-left: 1.875rem;
      position: relative;
      margin-bottom: 0.625rem; }
      .homeItem-inner ul li::before {
        position: absolute;
        top: 1px;
        left: 0;
        content: '';
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
  .homeItem-inner .link-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .homeItem-inner .link-wrap .link {
      font-weight: 500;
      font-size: 1.125rem;
      text-decoration: none;
      color: #00313C;
      display: inline-block;
      margin-right: 2.5rem; }
      .homeItem-inner .link-wrap .link:last-child {
        margin-right: 0; }
      .homeItem-inner .link-wrap .link.link-primary {
        color: #789D4A; }

/* #endregion */
/* #region Review */
.review-wrap {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  margin-bottom: 4.375rem;
  background-color: #f6f5f2;
  position: relative; }
  .review-wrap .review-wrap-bg {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../img/bg-review.png");
    background-repeat: no-repeat;
    background-size: 135%;
    background-position-x: 100%;
    background-position-y: center;
    width: 50%;
    height: 100%; }
    @media screen and (max-width: 991px) {
      .review-wrap .review-wrap-bg {
        display: none; } }
  .review-wrap.left .review-wrap-bg {
    right: unset;
    left: 0; }
  .review-wrap .review-carousel {
    position: static; }
  .review-wrap .owl-dots {
    position: absolute;
    top: calc(100% + 40px);
    left: 50%;
    transform: translateX(-50%); }
    .review-wrap .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(0, 49, 60, 0.4);
      margin: 5px 10px; }
      .review-wrap .owl-dots .owl-dot.active {
        background-color: #00313C; }

.review-text {
  position: relative;
  padding-top: 3.75rem;
  margin-right: 3.75rem;
  overflow: visible; }
  .review-text::before, .review-text::after {
    position: absolute;
    color: #ABC7C9;
    font-size: 6.25rem;
    font-family: 'Playfair', serif;
    z-index: 0; }
  .review-text::before {
    content: '“';
    top: -30px;
    left: 0; }
  .review-text::after {
    content: '”';
    bottom: -85px;
    right: 0; }
  .review-text p {
    font-size: 1rem;
    margin-bottom: 1.875rem; }
  .review-text h3 {
    font-family: 'Rubik',sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0; }

.review-media {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden; }
  .review-media img,
  .review-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border: 0; }
  .review-media .btn-play {
    width: 48px;
    height: 48px;
    min-height: unset;
    object-fit: unset;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
    .review-media .btn-play img {
      width: 100%;
      height: 100%;
      position: static;
      transform: unset; }

/* #endregion */
.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.875rem;
  margin-top: 1.25rem; }
  .feature-item p {
    max-width: 280px;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase; }

.feature-img {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.875rem; }
  .feature-img img {
    width: 100%;
    height: auto;
    object-fit: cover; }

.custom-modal .modal-dialog {
  flex: 1;
  min-height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center; }

.custom-modal .modal-content {
  border-radius: 0;
  border: 0; }
  .custom-modal .modal-content button.close {
    font-family: sans-serif;
    font-weight: 300;
    font-size: 2.3125rem;
    color: #fff;
    position: absolute;
    bottom: calc(100% + 3px);
    left: calc(100% + 3px);
    opacity: 1;
    text-shadow: none; }
    .custom-modal .modal-content button.close span {
      z-index: 1; }
    .custom-modal .modal-content button.close:hover {
      color: #789D4A; }

.breadcrumbs {
  padding-top: 2.1875rem;
  padding-bottom: 0rem; }
  .breadcrumbs * {
    font-size: 0.875rem; }
  .breadcrumbs-link {
    color: #000;
    display: inline-block;
    position: relative;
    margin-right: 1.25rem; }
    .breadcrumbs-link::after {
      content: '/';
      position: absolute;
      right: -15px;
      top: 0;
      pointer-events: none; }
    .breadcrumbs-link:hover {
      color: #D1CCBD;
      text-decoration: none; }
      .breadcrumbs-link:hover::after {
        color: #000; }

@media (min-width: 1300px) {
  /* #region Layout */
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1230px; }
  /* #endregion */ }

@media (min-width: 1500px) {
  /* #region Layout */
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1330px; }
  /* #endregion */ }

@media (max-width: 1509px) {
  /* #region Navbar */
  .navtop, .navbar {
    padding-left: 20px;
    padding-right: 20px; }
  .navbar-brand {
    margin-right: 1rem; }
  .navbar .btn {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    white-space: nowrap; }
  .navbar-nav .nav-item .nav-link {
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .navtop-search {
    margin-left: 0;
    margin-right: 0; }
  .contact-wrap .btn-tel {
    font-size: 0.8125rem;
    margin: 0 0.625rem; }
  .contact-wrap .btn {
    font-size: 0.8125rem;
    padding: 0.75rem; }
    .contact-wrap .btn.btn-w160 {
      min-width: 110px; }
  .navbar-brand svg {
    width: 130px; }
  .navbar.navbar-white, .navbar.scrolled {
    padding: 10px 20px; }
  /* #endregion */ }

@media (max-width: 991px) {
  /* #region Layout */
  /* #endregion */
  /* #region Typography */
  .h1, h1 {
    font-size: 2.375rem; }
  .h2, h2 {
    font-size: 1.875rem; }
  .h3, h3 {
    font-size: 1.625rem; }
  .h4, h4 {
    font-size: 1.25rem; }
  /* #endregion */
  /* #region Navbar */
  .navbar-nav {
    display: none; }
  .navbar .btn {
    padding-left: 1.375rem;
    padding-right: 1.375rem; }
  .hamburger {
    display: block; }
  .contact-wrap {
    display: none; }
  /* #endregion */
  .navtop-search .btn-search {
    color: #fff; }
  .big-header {
    height: 350px; }
    .big-header-carousel {
      width: 100%; }
      .big-header-carousel .owl-item-inner::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 49, 60, 0.2);
        z-index: 2; }
      .big-header-carousel .owl-dots {
        width: 100%;
        left: 0 !important;
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center; }
    .big-header-content {
      width: 100%;
      position: absolute;
      z-index: 3;
      background: transparent;
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      padding: 20px 30px; }
      .big-header-content .h1 {
        font-size: 3.125rem;
        color: #fff;
        white-space: normal; }
      .big-header-content p {
        margin-top: 1.25rem;
        margin-bottom: 0.9375rem;
        font-size: 1.25rem;
        color: #fff; }
      .big-header-content .link {
        color: #fff; }
    .big-header .quick-access {
      display: none; }
    .big-header .title-js {
      display: none; }
  .navbar {
    padding: 1.25rem; }
  .footer-top {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .footer-bottom {
    padding: 0.9375rem 0; }
    .footer-bottom-inner {
      flex-direction: column;
      text-align: center; }
  .footer .btn-wrap {
    align-items: flex-start; }
  .subscribe-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .subscribe-inner input {
      margin-left: 0; }
  .has-bg {
    background: none !important; }
  .textside {
    margin: 0;
    padding: 1.25rem; }
  .intro-wrap h2 {
    font-size: 2.1875rem; }
  .articles-masonry-item.md-item, .articles-masonry-item.lg-item {
    width: calc(50% - 10px); }
  .review-wrap {
    background-image: unset;
    padding: 3.125rem 1.875rem;
    margin-bottom: 0; }
    .review-wrap .owl-dots {
      bottom: 20px;
      top: unset; }
  .review-text {
    margin-top: 1.25rem;
    margin-right: 0;
    padding-top: 2.8125rem; }
    .review-text::after {
      bottom: -50px; }
  .content-map {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    display: flex;
    flex-direction: column-reverse; }
    .content-map-filter {
      width: 100%;
      position: static;
      transform: unset;
      padding: 0.9375rem; }
      .content-map-filter span {
        margin-bottom: 10px; }
      .content-map-filter .custom-checkbox {
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 0; }
    .content-map-inner {
      height: 400px; }
  .articles-masonry {
    margin-right: -5px; }
  .gallery-masonsry-item {
    width: calc(100% / 2) !important; } }

@media (max-width: 767px) {
  /* #region Layout */
  body {
    font-size: 0.875rem; }
  .section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  /* #endregion */
  /* #region Typography */
  .h1, h1 {
    font-size: 1.875rem; }
  .h2, h2, .title-30 {
    font-size: 1.75rem; }
  .h3, h3 {
    font-size: 1.5rem; }
  .h4, h4 {
    font-size: 1.125rem; }
  .intro-wrap h2 {
    font-size: 1.875rem;
    margin-bottom: 0.9375rem; }
  .btn {
    font-size: 0.875rem; }
  /* #endregion */
  /* #region Navbar */
  .navtop-review {
    display: none; }
  .navtop .nav-link {
    height: 20px; }
  /* #endregion */
  .big-header-content .h1 {
    font-size: 2.1875rem; }
  .big-header-content p {
    font-size: 1.125rem;
    margin-top: 0; }
  .footer .btn-wrap {
    margin-top: 1.5625rem; }
    .footer .btn-wrap .socials {
      margin-top: 1.25rem; }
  .articles-masonry-item.no-image .article-wrap {
    min-height: 300px; }
  .articles-masonry-item.md-item, .articles-masonry-item.lg-item {
    width: calc(100% - 10px);
    min-height: 300px; }
  .articles-masonry-item .article-wrap {
    min-height: 300px;
    overflow: hidden; }
    .articles-masonry-item .article-wrap img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      z-index: -1; }
  .articles-masonry-item .article-content {
    left: 0.9375rem;
    bottom: 0.9375rem; }
  .articles-masonry {
    margin-right: 5px;
    margin-left: 15px; } }

@media (max-width: 576px) {
  /* #region Layout */
  .section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  /* #endregion */
  /* #region Typography */
  /* #endregion */
  /* #region Navbar */
  .navtop-search.active .form-control {
    width: calc(100vw - 65px); }
  .navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    .navbar-brand svg {
      height: 40px;
      width: 101px; }
    .navbar .btn {
      display: none; }
  .hamburger {
    padding: 0.625rem 0.5625rem;
    margin-right: -9px; }
  /* #endregion */ }

.big-header.small-size {
  height: 400px; }
  .big-header.small-size .big-header-carousel {
    overflow: hidden;
    position: relative; }
    .big-header.small-size .big-header-carousel > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover; }
  .big-header.small-size .h1.title-js {
    top: calc(50% + 20px); }

.cta-wrap.left {
  text-align: left; }

.content p {
  font-size: 1.0625rem; }

.section.darker-bg, .section.bg-darker {
  background-color: #00313C; }
  .section.darker-bg .intro-wrap *, .section.bg-darker .intro-wrap * {
    color: #fff; }

.section.green-bg {
  background-color: #96A480; }
  .section.green-bg .intro-wrap * {
    color: #fff; }

.browner-bg {
  background-color: #D1CCBD; }

.image-card {
  height: calc(100% - 40px);
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column; }
  .image-card:hover .image-card-link img {
    transform: translate(-50%, -50%) scale(1.1); }
  .image-card-link {
    width: 100%;
    padding-bottom: 55%;
    display: block;
    position: relative;
    background: #ABC7C9;
    overflow: hidden; }
    .image-card-link img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform .3s ease-out; }
  .image-card-inner {
    background: #fff;
    padding: 1.5625rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1; }
    .image-card-inner span {
      display: inline-block;
      text-transform: uppercase;
      color: #ABC7C9;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0.75rem; }
    .image-card-inner h3 {
      font-size: 1.5rem;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0.75rem; }
    .image-card-inner p {
      font-size: 1rem;
      margin-bottom: 2.5rem; }
    .image-card-inner .textside {
      padding: 0;
      margin-top: 1rem; }
      .image-card-inner .textside p {
        margin-bottom: 1.5625rem; }
    .image-card-inner a {
      margin-top: auto;
      font-size: 1.125rem;
      font-weight: 500;
      color: #789D4A; }

.main-carousel {
  position: static;
  margin-top: 3.125rem; }
  .main-carousel-wrap {
    position: relative; }
  .main-carousel .item {
    position: relative; }
    .main-carousel .item img {
      width: 100%; }
    .main-carousel .item-desc {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem 2.1875rem;
      background: rgba(255, 255, 255, 0.8);
      width: 100%; }
      .main-carousel .item-desc p {
        font-size: 1.0625rem;
        margin-bottom: 0; }

.ct-slick {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ABC7C9;
  cursor: pointer; }
  .ct-slick-wrap {
    list-style: none; }
  .ct-slick.prev {
    left: 0; }
  .ct-slick.next {
    right: 0; }
  .ct-slick.slick-disabled {
    opacity: .4; }

.sub-carousel {
  margin-top: 1.25rem; }
  .sub-carousel .slick-slide {
    margin: 0 10px; }
  .sub-carousel .slick-list {
    margin: 0 -10px; }
  .sub-carousel .item-img {
    position: relative;
    height: 185px;
    overflow: hidden;
    cursor: pointer; }
    @media (max-width: 767px) {
      .sub-carousel .item-img {
        height: 85px; } }
    .sub-carousel .item-img img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.accordion-item {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.25rem; }

.accordion-header a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 500;
  color: #00313C;
  position: relative;
  background-color: #fff;
  text-decoration: none;
  padding: 0.8125rem 1.375rem;
  transition: all .3s ease-out;
  -webkit-appearance: none; }
  .accordion-header a span {
    max-width: calc(100% - 35px); }
  .accordion-header a:not(.collapsed) {
    color: #fff;
    background: #789D4A; }
    .accordion-header a:not(.collapsed) svg {
      transform: rotate(180deg); }

.accordion-content-inner {
  background: #fff;
  padding: 1.4375rem 1.375rem;
  font-size: 1.0625rem; }

.accordion.accordion-white {
  margin-left: 5rem; }
  .accordion.accordion-white .accordion-item {
    box-shadow: none;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 0; }
    .accordion.accordion-white .accordion-item:last-child {
      border-bottom: 0; }
  .accordion.accordion-white .accordion-header a {
    padding: 1.875rem; }
    .accordion.accordion-white .accordion-header a:not(.collapsed) {
      color: #789D4A;
      background: #F6F5F2; }
  .accordion.accordion-white .accordion-content-inner {
    background: #F6F5F2;
    padding: 1.25rem 1.875rem;
    padding-top: 0;
    padding-bottom: 2.5rem; }

.intro-wrap.img-overflow {
  display: flex;
  flex-direction: column; }
  .intro-wrap.img-overflow img {
    align-self: flex-end;
    max-width: 1500px; }

.btn-horizon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.25rem;
  margin-bottom: 1.875rem; }
  .btn-horizon .btn {
    width: auto;
    margin: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .btn-horizon .btn {
        font-size: 0.8125rem;
        padding-left: 15px;
        padding-right: 15px; } }
    .btn-horizon .btn.btn-secondary {
      color: #fff; }
    .btn-horizon .btn.btn-outline {
      background: #fff; }

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
  font-size: 0.875rem; }
  .form-group.form-select label {
    top: 0.5rem;
    font-size: 0.75rem;
    color: #152638;
    font-weight: 600;
    user-select: none; }
  .form-group label {
    position: absolute;
    color: #00313c;
    left: 1.1rem;
    top: 1.1rem;
    line-height: 13px;
    margin: 0;
    cursor: text;
    transition: all .125s ease-out;
    -webkit-transition: all .125s ease-out;
    -moz-transition: all .125s ease-out;
    -ms-transition: all .125s ease-out;
    -o-transition: all .125s ease-out;
    top: 0.5rem;
    font-size: 0.75rem;
    color: #152638;
    font-weight: 600; }
  .form-group .form-control {
    padding: 1.21875rem 1rem 0.3rem 1rem;
    height: 52px;
    border: 1px solid #00313c;
    border-radius: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    color: #152638 !important;
    background-color: #fff; }
    .form-group .form-control::placeholder {
      visibility: hidden;
      color: transparent;
      opacity: 0; }
    .form-group .form-control:focus, .form-group .form-control:not(:placeholder-shown) {
      border-color: #00313C;
      box-shadow: unset; }
      .form-group .form-control:focus::placeholder, .form-group .form-control:not(:placeholder-shown)::placeholder {
        visibility: hidden;
        color: transparent;
        opacity: 0; }
      .form-group .form-control:focus + label, .form-group .form-control:not(:placeholder-shown) + label {
        top: 0.5rem;
        font-size: 0.75rem;
        color: #152638;
        font-weight: 600; }
  .form-group textarea {
    max-width: 100%;
    min-height: 140px; }
  .form-group .bootstrap-select {
    width: 100% !important;
    display: block !important;
    font-size: 0.875rem;
    color: #D1CCBD; }
    .form-group .bootstrap-select > .dropdown-toggle {
      height: 52px;
      padding: 1.21875rem 1rem 0.3rem 1rem;
      border: solid 1px rgba(21, 38, 56, 0.2);
      border-radius: 0;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
      background-color: #fff;
      font-weight: 400; }
      .form-group .bootstrap-select > .dropdown-toggle:focus, .form-group .bootstrap-select > .dropdown-toggle:active, .form-group .bootstrap-select > .dropdown-toggle[aria-expanded="true"], .form-group .bootstrap-select > .dropdown-toggle[title]:not([title=""]) {
        box-shadow: unset;
        border-color: #00313C;
        outline: none !important; }
      .form-group .bootstrap-select > .dropdown-toggle:after {
        margin-right: -5px;
        border: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
        background-size: 24px 24px;
        margin-top: -15px; }
      .form-group .bootstrap-select > .dropdown-toggle .filter-option-inner-inner {
        font-size: 0.875rem; }
    .form-group .bootstrap-select .dropdown-menu.show {
      min-width: 100% !important;
      margin: 0;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      .form-group .bootstrap-select .dropdown-menu.show .dropdown-item {
        padding: .5rem 1rem;
        font-size: 0.875rem; }
        .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:hover {
          background-color: #00313C; }
        .form-group .bootstrap-select .dropdown-menu.show .dropdown-item.active, .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:active {
          background-color: #00313C; }
          .form-group .bootstrap-select .dropdown-menu.show .dropdown-item.active:hover, .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:active:hover {
            color: #fff; }

.form-control {
  box-shadow: none !important;
  color: #00313C;
  font-weight: 400; }
  .form-control:focus {
    color: #00313C; }

.custom-control-inline {
  margin-right: 2rem; }

.custom-control-label {
  font-size: 0.875rem; }
  .custom-control-label::before {
    background-color: #F7F7F8;
    border-color: rgba(0, 49, 60, 0.4); }
  .custom-control-label .label-selected {
    display: none; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00313C;
  border-color: #00313C; }

.custom-control-input:checked ~ .custom-control-label .label-selected {
  display: inline; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0rem; }

.custom-control-label::before,
.custom-control-label::after {
  width: 20px;
  height: 20px;
  top: 0;
  left: -1.8rem; }

.custom-control {
  padding-left: 1.875rem; }

.custom-control-input {
  width: 20px;
  height: 20px; }

.custom-checkbox.checkall .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'3'%20viewBox%3D'0%200%2010%203'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M8.9375%200H0.6875C0.300781%200%200%200.322266%200%200.6875V1.375C0%201.76172%200.300781%202.0625%200.6875%202.0625H8.9375C9.30273%202.0625%209.625%201.76172%209.625%201.375V0.6875C9.625%200.322266%209.30273%200%208.9375%200Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E");
  background-size: auto; }

.checkbox-list {
  margin-top: 1.25rem;
  margin-left: 1.875rem; }
  .checkbox-list .custom-checkbox {
    margin-bottom: 0.9375rem; }

.review-item .caption {
  margin-top: 0.9375rem;
  display: inline-block;
  margin-left: 3.125rem;
  color: #fff; }

@media (max-width: 991px) {
  .navbar.scrolled .navtop-search .btn-search {
    color: #ABC7C9; }
  .big-header.small-size {
    height: 250px; }
  .intro-wrap.img-overflow img {
    width: 100%; }
  .accordion.accordion-white {
    margin-left: 0;
    margin-top: 1.875rem; } }

.inner-page {
  padding-top: 6.0625rem; }

body.navbar-white {
  padding-top: 88px; }
  @media (max-width: 575px) {
    body.navbar-white {
      padding-top: 62px; } }

.rotate-270 {
  transform: rotate(270deg); }

.faq-search {
  margin-bottom: 5rem; }
  .faq-search .form-group {
    position: relative; }
    .faq-search .form-group .form-control {
      padding-right: 2.5rem; }
  .faq-search .button-submit {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background-color: transparent; }
    .faq-search .button-submit svg {
      transform: rotate(270deg); }

.cta-wrap-multi {
  margin-top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cta-wrap-multi .btn {
    min-width: 220px;
    margin: 0.3125rem 1.5625rem; }

.intro-wrap .note {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 4.375rem;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.875rem;
  font-family: 'Playfair Display',serif; }

.intro-wrap h2 {
  text-transform: uppercase; }

.review-carousel-nodots .owl-dots {
  display: none; }

.review-wrap.left {
  background-position: left center; }
  .review-wrap.left h2 {
    margin-left: 3.75rem; }
  .review-wrap.left .review-text {
    margin-right: 0;
    margin-left: 3.75rem; }
  .review-wrap.left .review-item .caption {
    display: flex;
    justify-content: flex-end;
    margin-left: unset;
    margin-right: 2.75rem; }

@media (max-width: 991px) {
  .cta-wrap-multi {
    flex-direction: column; }
    .cta-wrap-multi .btn {
      width: 100%;
      max-width: 300px; }
  .review-wrap.left .review-text,
  .review-wrap.left h2 {
    margin-left: 0; }
  .review-wrap .review-item .caption {
    color: #00313C;
    display: flex;
    justify-content: flex-start !important;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 575px) {
  .cta-wrap-multi .btn {
    max-width: unset; } }

.detail-wrap {
  padding-top: 2.5rem; }

.detail-thumbnail, .detail-desc {
  margin-bottom: 1.25rem;
  height: calc(100% - 20px);
  width: 100%; }

.detail-thumbnail {
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center; }
  .detail-thumbnail .sold {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 0.8125rem;
    color: #fff;
    background-color: #00313C;
    text-transform: uppercase; }
  .detail-thumbnail img {
    max-width: 100%; }

.detail-desc .feature-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.625rem; }
  .detail-desc .feature-list img {
    margin-right: 0.3125rem; }
  .detail-desc .feature-list span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2.5rem;
    margin-bottom: 0.125rem; }
    .detail-desc .feature-list span:last-child {
      margin-right: 0; }

.detail-desc h3 {
  font-size: 2.8125rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1.25rem; }

.detail-desc-inner p {
  font-size: 1rem;
  margin-bottom: 1.25rem; }

.detail-desc-inner ul {
  padding: 0;
  list-style: none;
  margin-bottom: 1.25rem; }
  .detail-desc-inner ul li {
    position: relative;
    padding-left: 2.1875rem;
    margin-bottom: 0.4375rem; }
    .detail-desc-inner ul li::marker {
      content: ''; }
    .detail-desc-inner ul li::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.25C4.60059 0.25 0.25 4.60059 0.25 10C0.25 15.3994 4.60059 19.75 10 19.75C15.3994 19.75 19.75 15.3994 19.75 10C19.75 8.95117 19.6094 7.90527 19.2344 6.92969L18.0156 8.125C18.165 8.72559 18.25 9.32617 18.25 10C18.25 14.5762 14.5762 18.25 10 18.25C5.42383 18.25 1.75 14.5762 1.75 10C1.75 5.42383 5.42383 1.75 10 1.75C12.25 1.75 14.2715 2.64648 15.6953 4.07031L16.75 3.01562C15.0244 1.29004 12.625 0.25 10 0.25ZM18.4609 3.46094L10 11.9219L6.78906 8.71094L5.71094 9.78906L9.46094 13.5391L10 14.0547L10.5391 13.5391L19.5391 4.53906L18.4609 3.46094Z' fill='%2396A480'/%3E%3C/svg%3E%0A"); }

.detail-desc .btn-wrap {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  padding-top: 0.625rem; }
  .detail-desc .btn-wrap .btn {
    margin: 0.3125rem 0.625rem;
    min-width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }

.masterplace {
  width: 100%; }
  .masterplace-img {
    width: 100%;
    height: auto; }
  .masterplace-pin {
    position: absolute;
    font-size: 0.6875rem;
    font-weight: 600;
    min-width: 20px;
    height: 20px;
    border-radius: 20rem;
    color: #fff;
    background: #789D4A;
    display: flex;
    align-items: center;
    justify-content: center; }
  .masterplace-carousel {
    position: relative; }
    .masterplace-carousel .owl-dots {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%); }
      .masterplace-carousel .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(0, 49, 60, 0.4);
        margin: 5px 10px; }
        .masterplace-carousel .owl-dots .owl-dot.active {
          background-color: #00313c; }

.popover {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); }
  .popover-body {
    font-size: 1rem; }

.tooltip.show {
  opacity: 1; }

.tooltip-inner {
  background-color: #fff;
  border-radius: 0.1875rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #00313C;
  padding: 0.3125rem 0.75rem; }

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: #fff; }

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff; }

.breadcrumbs {
  padding-top: 2.1875rem;
  padding-bottom: 0rem; }
  .breadcrumbs * {
    font-size: 0.875rem; }
  .breadcrumbs-link {
    color: #000;
    display: inline-block;
    position: relative;
    margin-right: 1.25rem; }
    .breadcrumbs-link::after {
      content: '/';
      position: absolute;
      right: -15px;
      top: 0;
      pointer-events: none; }
    .breadcrumbs-link:hover {
      color: #D1CCBD;
      text-decoration: none; }
      .breadcrumbs-link:hover::after {
        color: #000; }

.blog-filter {
  margin-bottom: 1.25rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  padding-top: 1.875rem;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none; }
  .blog-filter li {
    margin: 0.3125rem 0.625rem; }
    .blog-filter li button {
      display: block;
      color: #789D4A;
      text-decoration: none;
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      padding: 0.9375rem 2.1875rem;
      border: solid 3px #789D4A;
      border-radius: 10rem;
      background-color: #fff;
      transition: all .2s linear; }
      .blog-filter li button:hover {
        color: #fff;
        background: #91b664; }
      .blog-filter li button.active {
        color: #fff;
        background: #789D4A; }

.blog-pagination {
  margin-top: 1.25rem; }
  .blog-pagination ul {
    padding: 0;
    margin-bottom: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none; }
    .blog-pagination ul li a {
      font-size: 1rem;
      border-radius: 20rem;
      margin: 0.3125rem;
      color: #000;
      display: block;
      text-decoration: none; }
      .blog-pagination ul li a.active {
        color: #fff;
        background: #EAA794; }
    .blog-pagination ul li:not(.prev):not(.next) a {
      height: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .blog-pagination ul li.prev {
      margin-right: 0.625rem; }
    .blog-pagination ul li.next {
      margin-left: 0.625rem; }

.articles-masonry-item.pink .article-content * {
  color: #fff; }

.articles-masonry-item.pink .article-wrap:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ffffff'/%3E%3C/svg%3E") no-repeat;
  background-position: 50%; }

.articles-masonry-item.brown .article-wrap:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23D1CCBD'/%3E%3C/svg%3E") no-repeat;
  background-position: 50%; }

.articles-masonry-item.blue .article-wrap:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
  background-position: 50%; }

.articles-masonry-item.green .article-wrap:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
  background-position: 50%; }

.articles-masonry-item.darker .article-wrap:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
  background-position: 50%; }

a {
  color: #789D4A; }
  a:hover {
    color: #789D4A; }

.elementbenefitscontent .section:not(.grey-bg):not(.brown-bg) .textside {
  background: #eee; }

.section.brown-bg .accordion.accordion-white .accordion-header a:not(.collapsed) {
  color: #fff;
  background: #EAA794; }

.section.brown-bg .accordion.accordion-white .accordion-content-inner {
  padding: 1.875rem;
  background: #fff; }

.intro-wrap h2 {
  text-transform: uppercase; }

.intro-wrap img {
  max-width: 100%;
  height: auto; }

.navbar {
  text-transform: uppercase; }

.bootstrap-select > select {
  height: 52px !important; }

.userform-dropdown {
  min-height: 52px; }

.dropdown-menu.show .dropdown-menu.inner {
  display: block !important; }

.search-item {
  padding: 2.1875rem 0;
  border-bottom: solid 1px rgba(209, 204, 189, 0.5); }
  .search-item h4 {
    font-size: 1.875rem;
    margin-bottom: 1.25rem; }
    .search-item h4 a {
      color: #00313C;
      text-decoration: none; }
      .search-item h4 a:hover {
        text-decoration: none; }
  .search-item p {
    margin-bottom: 1.5625rem; }
  .search-item .link {
    font-size: 1.0625rem;
    color: #EAA794;
    text-decoration: underline; }

.gallery-masonsry-item {
  width: calc(100% / 3);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px; }
  .gallery-masonsry-item a {
    display: block; }
    .gallery-masonsry-item a img {
      width: 100%;
      height: auto; }

.waving {
  color: #fff; }
  .waving svg {
    width: 100%;
    height: auto; }
  .waving.brown-bg {
    background-color: unset;
    color: #f6f5f2; }
  .waving.grey-bg {
    background-color: unset;
    color: #eee; }
  .waving.bg-darkder {
    background-color: unset;
    color: #00313C; }
  .waving.bg-pastel, .waving.bg-pink {
    background-color: unset;
    color: #EAA794; }
  .waving.bg-blue {
    background-color: unset;
    color: #ABC7C9; }
  .waving.bg-brown {
    background-color: unset;
    color: #D1CCBD; }
  .waving.bg-green {
    background-color: unset;
    color: #96A480; }

@keyframes drawing {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: 1550; } }

[data-aos="draw"].aos-animate .path-shadow {
  animation: drawing 2s ease-in-out forwards; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.embed-wrap > iframe {
  width: 100%;
  max-height: calc(100vh - 92px); }
  @media (max-width: 576px) {
    .embed-wrap > iframe {
      max-height: calc(100vh - 81px); } }

body.no-layout {
  padding-top: 0; }
  body.no-layout .fixed-top,
  body.no-layout .subscribe,
  body.no-layout .footer {
    display: none; }
  body.no-layout .embed-wrap > iframe {
    max-height: calc(100vh - 23px); }
    @media (max-width: 576px) {
      body.no-layout .embed-wrap > iframe {
        max-height: calc(100vh - 23px); } }

.custom-file-label {
  height: 52px;
  border: 1px solid #00313C;
  border-radius: 0;
  box-shadow: none !important;
  display: flex;
  align-items: center; }
  .custom-file-label::after {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    color: #fff;
    background-color: #00313C; }

.home-floorplan-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%; }
  .home-floorplan-text .sub {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #ABC7C9; }
  .home-floorplan-text .h3 {
    margin-bottom: 1.25rem;
    font-size: 2.8125rem; }
  .home-floorplan-text p {
    margin-bottom: 0; }
  .home-floorplan-text .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2.1875rem; }
    .home-floorplan-text .btn-wrap .btn {
      width: 100%; }
      .home-floorplan-text .btn-wrap .btn + .btn {
        margin-top: 0.9375rem; }

@media (max-width: 991px) {
  .home-floorplan-text {
    margin-bottom: 1.875rem; }
    .home-floorplan-text .btn-wrap {
      margin-top: 1.25rem; }
      .home-floorplan-text .btn-wrap .btn + .btn {
        margin-top: 0.625rem; } }

.home-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  padding: 1.875rem 3.125rem; }
  .home-contact .h3 {
    font-size: 1.5rem;
    font-family: Rubik,sans-serif;
    margin-bottom: 1.25rem;
    color: #00313C;
    font-weight: 500; }
  .home-contact h5 {
    font-size: 1.125rem;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 2px;
    margin-bottom: 0.625rem; }
  .home-contact p {
    margin-bottom: 0; }
  .home-contact .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2.1875rem; }
    .home-contact .btn-wrap .btn {
      width: 100%; }
      .home-contact .btn-wrap .btn + .btn {
        margin-top: 0.9375rem; }
  @media (max-width: 991px) {
    .home-contact {
      padding: 1.25rem 1.875rem; }
      .home-contact .btn-wrap {
        margin-top: 1.25rem; }
        .home-contact .btn-wrap .btn + .btn {
          margin-top: 0.625rem; } }

.iframe-section iframe {
  width: 100%;
  height: 650px; }

.masterplace-carousel {
  height: 900px;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .masterplace-carousel {
      height: auto; } }

.item-thumb-carousel {
  position: relative; }
  .item-thumb-carousel .thumb-tag {
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 3px;
    background: #00313C;
    color: #fff;
    font-weight: 300;
    padding: 0.125rem 0.625rem;
    z-index: 1; }
  .item-thumb-carousel .slick-thumb-carousel,
  .item-thumb-carousel .slick-thumb-nav {
    width: auto;
    height: 100%; }
    .item-thumb-carousel .slick-thumb-carousel .slick-item-inner,
    .item-thumb-carousel .slick-thumb-nav .slick-item-inner {
      width: 100%;
      padding-bottom: 60%;
      position: relative;
      overflow: hidden; }
      .item-thumb-carousel .slick-thumb-carousel .slick-item-inner > img,
      .item-thumb-carousel .slick-thumb-nav .slick-item-inner > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .item-thumb-carousel .slick-thumb-carousel .slick-prev, .item-thumb-carousel .slick-thumb-carousel .slick-next {
    position: absolute;
    top: calc(50% - 18px);
    z-index: 99;
    background: #00313C;
    border: 0px;
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .item-thumb-carousel .slick-thumb-carousel .slick-prev {
    left: 15px; }
  .item-thumb-carousel .slick-thumb-carousel .slick-next {
    right: 15px; }
  .item-thumb-carousel .slick-thumb-carousel .slick-track {
    display: flex !important; }
  .item-thumb-carousel .slick-thumb-carousel .slick-slide {
    height: inherit !important; }
  @media (max-width: 991px) {
    .item-thumb-carousel .slick-thumb-nav {
      margin-left: -5px;
      margin-right: -5px; } }
  .item-thumb-carousel .slick-thumb-nav .slick-item {
    margin-bottom: 0.75rem; }
    @media (max-width: 991px) {
      .item-thumb-carousel .slick-thumb-nav .slick-item {
        margin-bottom: 0;
        padding-left: 5px;
        padding-right: 5px; } }
    .item-thumb-carousel .slick-thumb-nav .slick-item.slick-current .slick-item-inner {
      position: relative; }
      .item-thumb-carousel .slick-thumb-nav .slick-item.slick-current .slick-item-inner::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }

.home-banner-status {
  background: #789D4A;
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 99; }

.home-design-read-more {
  color: #789D4A !important; }
  .home-design-read-more:hover {
    color: #fff !important; }

.rotated-icon {
  transform: rotate(180deg); }

.bottom-content {
  margin-bottom: 0;
  font-size: 0.875rem; }
  .bottom-content > * {
    font-size: 0.875rem;
    margin-bottom: 0; }
