.breadcrumbs {
    padding-top: rem-calc(35);
    padding-bottom: rem-calc(0);
    * {
        font-size: rem-calc(14);
    }
    &-link {
        color: #000;
        display: inline-block;
        position: relative;
        margin-right: rem-calc(20);
        &::after {
            content: '/';
            position: absolute;
            right: -15px;
            top: 0;
            pointer-events: none;
        }
        &:hover {
            color: $color-brown;
            text-decoration: none;
            &::after {
                color: #000;
            }
        }
    }
}
.blog {
    &-filter {
        margin-bottom: rem-calc(20);
        margin-left: rem-calc(-10);
        margin-right: rem-calc(-10);
        padding-top: rem-calc(30);
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        li {
            margin: rem-calc(5) rem-calc(10);
            button {
                display: block;
                color: $color-orange;
                text-decoration: none;
                font-size: rem-calc(15);
                font-weight: 500;
                line-height: 1;
                text-transform: uppercase;
                padding: rem-calc(15) rem-calc(35);
                border: solid 3px $color-orange;
                border-radius: 10rem;
                background-color: #fff;
                transition: all .2s linear;
                &:hover {
                    color: #fff;
                    background: lighten($color: $color-orange, $amount: 10);
                }
                &.active {
                    color: #fff;
                    background: $color-orange;
                }
            }
        }
    }
    &-pagination {
        margin-top: rem-calc(20);
        ul {
            padding: 0;
            margin-bottom: rem-calc(10);
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            li {
                a {
                    font-size: rem-calc(16);
                    border-radius: 20rem;
                    margin: rem-calc(5);
                    color: #000;
                    display: block;
                    text-decoration: none;
                    &.active {
                        color: #fff;
                        background: $color-pastel;
                    }
                }
                &:not(.prev):not(.next) {
                    a {
                        height: 30px;
                        min-width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                &.prev {
                    margin-right: rem-calc(10);
                }
                &.next {
                    margin-left: rem-calc(10);
                }
            }
        }
    }
}

.articles-masonry-item {
    &.pink {
        .article-content * {
            color: #fff;
        }
        .article-wrap:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ffffff'/%3E%3C/svg%3E") no-repeat;
            background-position: 50%;
        }
    }
    &.brown {
        .article-content * {
            @extend .text-brown;
        }
        .article-wrap:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23D1CCBD'/%3E%3C/svg%3E") no-repeat;
            background-position: 50%;
        }
    }
    &.blue {
        .article-content * {
            @extend .text-white;
        }
        .article-wrap:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
            background-position: 50%;
        }
    }
    &.green {
        .article-content * {
            @extend .text-white;
        }
        .article-wrap:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
            background-position: 50%;
        }
    }
    &.darker {
        .article-content * {
            @extend .text-darker;
        }
        .article-wrap:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
            background-position: 50%;
        }
    }
}

a {
    color: $color-orange;
    &:hover {
        color: $color-orange;
    }
}
.elementbenefitscontent{
    .section{
        &:not(.grey-bg):not(.brown-bg){
            .textside{
                background: #eee;
            }
        }
    }
}
.section {
    &.brown-bg {
        .accordion {
            &.accordion-white {
                .accordion-header {
                    a:not(.collapsed) {
                        color: #fff;
                        background: $color-pastel;
                    }
                } 
                .accordion-content {
                    &-inner {
                        padding: 1.875rem;
                        background: #fff
                    }
                }
            }
        }
    }
}

.intro-wrap {
    h2 {
        text-transform: uppercase;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.navbar {
    text-transform: uppercase;
}

.bootstrap-select>select {
    height: 52px !important;
}

.userform-dropdown {
    min-height: 52px;
}

.dropdown-menu.show {
    .dropdown-menu.inner {
        display: block !important;
    }
}

.search-item {
    padding: rem-calc(35) 0;
    border-bottom: solid 1px rgba($color-brown,.5);
    h4 {
        font-size: rem-calc(30);
        margin-bottom: rem-calc(20);
        a {
            color: $color-darker;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    p {
        margin-bottom: rem-calc(25);
    }
    .link {
        font-size: rem-calc(17);
        color: $color-pastel;
        text-decoration: underline;
    }
}

.gallery-masonsry {
    &-wrap {}
    &-item {
        width: calc(100% / 3);
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        a {
            display: block;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.waving {
    color: #fff;
    svg {
        width: 100%;
        height: auto;
    }
    &.brown-bg {
        background-color: unset;
        color: #f6f5f2;
    }
    &.grey-bg {
        background-color: unset;
        color: #eee;
    }
    &.bg {
        &-darkder {
            background-color: unset;
            color: $color-darker;
        }
        &-pastel,
        &-pink {
            background-color: unset;
            color: $color-pastel;
        }
        &-blue {
            background-color: unset;
            color: $color-blue;
        }
        &-brown {
            background-color: unset;
            color: $color-brown;
        }
        &-green {
            background-color: unset;
            color: $color-green;
        }
    }
}

@keyframes drawing {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 1550;
    }
}

[data-aos="draw"]{
    &.aos-animate{
        .path-shadow {
            animation: drawing 2s ease-in-out forwards;
        }
    }
}

.no-padding-bottom{
    padding-bottom: 0!important;
}
.no-padding-top{
    padding-top: 0!important;
}
.embed-wrap {
    &>iframe {
        width: 100%;
        max-height: calc(100vh - 92px);
        @media (max-width: 576px) {
            max-height: calc(100vh - 81px);
        }
    }
}
body.no-layout {
    padding-top: 0;
    .fixed-top,
    .subscribe,
    .footer {
        display: none;
    }
    .embed-wrap {
        &>iframe {
            max-height: calc(100vh - 23px);
            @media (max-width: 576px) {
                max-height: calc(100vh - 23px);
            }
        }
    }
}
.custom-file-label {
    height: 52px;
    border: 1px solid $color-darker;
    border-radius: 0;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    &::after {
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 0;
        color: #fff;
        background-color: $color-darker;
    }
}

.home{
    &-floorplan {
        &-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //justify-content: center;
            height: 100%;
            .sub {
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: rem-calc(8);
                color: #ABC7C9;
            }
            .h3 {
                margin-bottom: rem-calc(20);
                font-size: rem-calc(45);
            }
            p {
                margin-bottom: 0;
            }
            .btn-wrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: rem-calc(35);
                .btn {
                    width: 100%;
                    & + .btn {
                        margin-top: rem-calc(15);
                    }
                }
            }
        }
        @media (max-width: 991px) {
            &-text {
                margin-bottom: rem-calc(30);
                .btn-wrap {
                    margin-top: rem-calc(20);
                    .btn {
                        & + .btn {
                            margin-top: rem-calc(10);
                        }
                    }
                }
            }
        }
    }
    &-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        padding: rem-calc(30) rem-calc(50);
        .h3 {
            font-size: rem-calc(24);
            font-family: Rubik,sans-serif;
            margin-bottom: rem-calc(20);
            color: $color-darker;
            font-weight: 500;
        }
        h5 {
            font-size: rem-calc(18);
            font-weight: 400;
            text-decoration: underline;
            text-underline-offset: 2px;
            margin-bottom: rem-calc(10);
        }
        p {
            margin-bottom: 0;
        }
        .btn-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: rem-calc(35);
            .btn {
                width: 100%;
                & + .btn {
                    margin-top: rem-calc(15);
                }
            }
        }
        @media (max-width: 991px) {
            padding: rem-calc(20) rem-calc(30);
            .btn-wrap {
                margin-top: rem-calc(20);
                .btn {
                    & + .btn {
                        margin-top: rem-calc(10);
                    }
                }
            }
        }
    }
}

.iframe-section iframe{
    width: 100%;
    height: 650px;
}

.masterplace-carousel{
    height: 900px;
    overflow: hidden;
    @media (max-width: 1023px) {
        height: auto;
    }
}

.item-thumb-carousel {
    position:relative;
    .thumb-tag {
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 3px;
        background: $color-darker;
        color: #fff;
        font-weight: 300;
        padding: rem-calc(2) rem-calc(10);
        z-index: 1;
    }
    .slick-thumb-carousel,
    .slick-thumb-nav {
        width: auto;
        height: 100%;
        .slick-item {
            &-inner {
                width: 100%;
                padding-bottom: 60%;
                position: relative;
                overflow: hidden;
                &>img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .slick-thumb-carousel {
        .slick-prev, .slick-next {
            position: absolute;
            top: calc(50% - 18px);
            z-index: 99;
            background: $color-darker;
            border: 0px;
            color: #fff;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .slick-prev {
            left:15px;
        }
        .slick-next {
            right:15px;
        }
        .slick {
            &-track {
                display: flex !important;
            }
            &-slide {
                height: inherit !important;
            }
        }
    }

    .slick-thumb-nav {
        @media (max-width: 991px) {
            margin-left: -5px;
            margin-right: -5px;
        }
        .slick {
            &-item {
                margin-bottom: rem-calc(12);
                @media (max-width: 991px) {
                    margin-bottom: 0;
                    padding-left: 5px;
                    padding-right: 5px;
                }
                &.slick-current {
                    .slick-item-inner {
                        position: relative;
                        &::before {
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            //border: solid 2px $color-darker;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

.home-banner-status {
    background:$color-orange;
    padding:5px 10px;
    color:#fff;
    position:absolute;
    left:10px;
    top:10px;
    z-index:99;
}

.home-design-read-more {
    color:$color-orange !important;
    &:hover {
        color:#fff !important;
    }
}

.rotated-icon {
    transform: rotate(180deg);
}

.bottom-content {
    margin-bottom: 0;
    font-size: rem-calc(14);
    & > * {
        font-size: rem-calc(14);
        margin-bottom: 0;
    }
}