.detail {
    &-wrap {
        padding-top: rem-calc(40);
    }
    &-thumbnail,
    &-desc {
        margin-bottom: rem-calc(20);
        height: calc(100% - 20px);
        width: 100%;
        //padding: rem-calc(25);
    }
    &-thumbnail {
        position: relative;
        
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        .sold {
            position: absolute;
            top: 0;
            right: 0;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            font-size: rem-calc(13);
            color: #fff;
            background-color: $color-darker;
            text-transform: uppercase;
        }
        img {
            max-width: 100%;
        }
    }
    &-desc {
        //background-color: #F6F5F2;
        .feature-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: rem-calc(10);
            img {
                margin-right: rem-calc(5);
            }
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: rem-calc(40);
                margin-bottom: rem-calc(2);
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        h3 {
            font-size: rem-calc(45);
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: rem-calc(20);
        }
        &-inner {
            p {
                font-size: rem-calc(16);
                margin-bottom: rem-calc(20);
            }
            ul {
                padding: 0;
                list-style: none;
                margin-bottom: rem-calc(20);
                li {
                    position: relative;
                    padding-left: rem-calc(35);
                    margin-bottom: rem-calc(7);
                    &::marker {
                        content: '';
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 20px;
                        height: 20px;
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.25C4.60059 0.25 0.25 4.60059 0.25 10C0.25 15.3994 4.60059 19.75 10 19.75C15.3994 19.75 19.75 15.3994 19.75 10C19.75 8.95117 19.6094 7.90527 19.2344 6.92969L18.0156 8.125C18.165 8.72559 18.25 9.32617 18.25 10C18.25 14.5762 14.5762 18.25 10 18.25C5.42383 18.25 1.75 14.5762 1.75 10C1.75 5.42383 5.42383 1.75 10 1.75C12.25 1.75 14.2715 2.64648 15.6953 4.07031L16.75 3.01562C15.0244 1.29004 12.625 0.25 10 0.25ZM18.4609 3.46094L10 11.9219L6.78906 8.71094L5.71094 9.78906L9.46094 13.5391L10 14.0547L10.5391 13.5391L19.5391 4.53906L18.4609 3.46094Z' fill='%2396A480'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
        .btn-wrap {
            margin-left: rem-calc(-10);
            margin-right: rem-calc(-10);
            padding-top: rem-calc(10);
            .btn {
                margin: rem-calc(5) rem-calc(10);
                min-width: 140px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &-secondary {
                }
            }
        }
    }
}

.masterplace {
    width: 100%;
    &-img {
        width: 100%;
        height: auto;
    }
    &-pin {
        position: absolute;
        font-size: rem-calc(11);
        font-weight: 600;
        min-width: 20px;
        height: 20px;
        border-radius: 20rem;
        color: #fff;
        background: $color-orange;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-carousel {
        position: relative;
        .owl-dots {
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            .owl-dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: rgba(0,49,60,.4);
                margin: 5px 10px;
                &.active {
                    background-color: #00313c;
                }
            }
        }
    }
}

.popover {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    &-body {
        font-size: rem-calc(16);
    }
}

.tooltip{
    &.show{opacity: 1;}
    &-inner{
      background-color: #fff;
      border-radius: rem-calc(3);
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      font-size: rem-calc(16);
      color: $color-darker;
      padding:rem-calc(5) rem-calc(12);
    }
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
border-top-color: #fff;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
border-bottom-color: #fff;
}