.big-header {
    &.small-size {
        height: 400px;
        .big-header {
            &-carousel {
                overflow: hidden;
                position: relative;
                &>img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                }
            }
        }
        .h1 {
            &.title-js {
                top: calc(50% + 20px);
            }
        }
    }
}

.cta-wrap.left {
    text-align: left;
}

.content {
    p {
        font-size: rem-calc(17);
    }
}

.section {
    &.darker-bg,
    &.bg-darker {
        background-color: $color-darker;
        .intro-wrap {
            * {
                color: #fff;
            }
        }
    }
    &.green-bg {
        background-color: $color-green;
        .intro-wrap {
            * {
                color: #fff;
            }
        }
    }
}

.browner-bg {
    background-color: $color-brown;
}

.image-card {
    height: calc(100% - 40px);
    margin-top: rem-calc(40);
    display: flex;
    flex-direction: column;
    &:hover {
        .image-card-link {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
    &-link {
        width: 100%;
        padding-bottom: 55%;
        display: block;
        position: relative;
        background: $color-blue;
        overflow: hidden;
        img {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: transform .3s ease-out;
        }
    }
    &-inner {
        background: #fff;
        padding: rem-calc(25) rem-calc(20);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        span {
            display: inline-block;
            text-transform: uppercase;
            color: $color-blue;
            font-size: rem-calc(14);
            font-weight: 600;
            margin-bottom: rem-calc(12);
        }
        h3 {
            font-size: rem-calc(24);
            font-family: "Rubik", sans-serif;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: rem-calc(12);
        }
        p {
            font-size: rem-calc(16);
            margin-bottom: rem-calc(40);
        }
        .textside{
            padding: 0;
            margin-top: 1rem;
            p{
                margin-bottom: rem-calc(25);
            }
        }
        a {
            margin-top: auto;
            font-size: rem-calc(18);
            font-weight: 500;
            color: $color-orange;
        }
    }
}

.main-carousel {
    position: static;
    margin-top: rem-calc(50);
    &-wrap {
        position: relative;
    }
    .item {
        position: relative;
        img {
            width: 100%;
        }
        &-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: rem-calc(16) rem-calc(35);
            background: rgba(#fff,.8);
            width: 100%;
            p {
                font-size: rem-calc(17);
                margin-bottom: 0;
            }
        }
    }
}

.ct-slick {
    position: absolute;
    top: 50%;
    z-index: 2;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: $color-blue;
    cursor: pointer;
    &-wrap {
        list-style: none;
    }
    &.prev {
        left: 0;
    }
    &.next {
        right: 0;
    }
    &.slick-disabled {
        opacity: .4;
    }
}

.sub-carousel {
    margin-top: rem-calc(20);
    .slick-slide {
        margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }
    .item {
        &-img {
            position: relative;
            height: 185px;
            overflow: hidden;
            cursor: pointer;
            @media (max-width: 767px) {
                height: 85px;
            }
            img {
                width: 100%;
                min-height: 100%;
                object-fit: cover;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
}

.accordion {
    &-item {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: rem-calc(20);
    }
    &-header {
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: rem-calc(20);
            font-weight: 500;
            color: $color-darker;
            position: relative;
            background-color: #fff;
            text-decoration: none;
            padding: rem-calc(13) rem-calc(22);
            transition: all .3s ease-out;
            -webkit-appearance: none;
            span {
                max-width: calc(100% - 35px);
            }
            &:not(.collapsed) {
                color: #fff;
                background: $color-orange;
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &-content {
        &-inner {
            background: #fff;
            padding: rem-calc(23) rem-calc(22);
            font-size: rem-calc(17);
        }
    }
    &.accordion-white {
        margin-left: rem-calc(80);
        .accordion-item {
            box-shadow: none;
            border-bottom: 1px solid #E0E0E0;
            margin-bottom: 0;
            &:last-child {
                border-bottom: 0;
            }
        }
        .accordion-header {
            a {
                padding: rem-calc(30);
                &:not(.collapsed) {
                    color: $color-orange;
                    background: #F6F5F2;
                }
            }
        }
        .accordion-content {
            &-inner {
                background: #F6F5F2;
                padding: rem-calc(20) rem-calc(30);
                padding-top: 0;
                padding-bottom: rem-calc(40);
            }
        }
    }
}

.intro-wrap {
    &.img-overflow {
        display: flex;
        flex-direction: column;
        img {
            align-self: flex-end;
            max-width: 1500px;
        }
    }
}

.btn-horizon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: rem-calc(20);
    margin-bottom: rem-calc(30);
    .btn {
        width: auto;
        margin: rem-calc(10);
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            font-size: rem-calc(13);
            padding-left: 15px;
            padding-right: 15px;
        }
        &.btn-secondary {
            color: #fff;
        }
        &.btn-outline {
            background: #fff;
        }
    }
}

.form-group {
    position: relative;
    margin-bottom: rem-calc(20);
    font-size: rem-calc(14);
    &.form-select {
      label {
        top: rem-calc(8);
        font-size: rem-calc(12);
        color: rgba(21,38,56,1);
        font-weight: 600;
        user-select: none;
      }
    }
    label {
      position: absolute;
      color: #00313c;
      left: 1.1rem;
      top: 1.1rem;
      line-height: 13px;
      margin: 0;
      cursor: text;
      transition: all .125s ease-out;
      -webkit-transition: all .125s ease-out;
      -moz-transition: all .125s ease-out;
      -ms-transition: all .125s ease-out;
      -o-transition: all .125s ease-out;
        top: rem-calc(8);
        font-size: rem-calc(12);
        color: rgba(21,38,56,1);
        font-weight: 600;
    }

    .form-control {
      padding: 1.21875rem 1rem 0.3rem 1rem;
      height: 52px;
      border: 1px solid #00313c;
      border-radius: 0;
      box-shadow: 0px 2px 5px rgba(0,0,0,0.07);
      color: rgba(21,38,56,1) !important;
      background-color: #fff;
      &::placeholder {
        visibility: hidden;
        color: transparent;
        opacity: 0;
      }
      &:focus,&:not(:placeholder-shown) {
        border-color: $color-darker;
        box-shadow: unset;
        &::placeholder{
          visibility: hidden;
          color: transparent;
          opacity: 0;
        }
        & + label {
          top: rem-calc(8);
          font-size: rem-calc(12);
          color: rgba(21,38,56,1);
          font-weight: 600;
        }
      }
    }
    textarea {
      max-width: 100%;
      min-height: 140px;
    }
    .bootstrap-select {
      width: 100%!important;
      display: block!important;
      font-size: rem-calc(14);
      color: $color-brown;
      &>.dropdown-toggle {
        height: 52px;
        padding: 1.21875rem 1rem 0.3rem 1rem;
        border: solid 1px rgba(21,38,56,.2);
        border-radius: 0;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.07);
        background-color: #fff;
        font-weight: 400;
        &:focus,
        &:active,
        &[aria-expanded="true"],
        &[title]:not([title=""]) {
          box-shadow: unset;
          border-color: $color-darker;
          outline: none !important;
        }
        &:after {
          margin-right: -5px;
          border: 0;
          width: 1.5rem;
          height: 1.5rem;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
          background-size: 24px 24px;
          margin-top: -15px;
        }
        .filter-option-inner-inner {
          font-size: rem-calc(14);
        }
      }
      .dropdown-menu.show {
        min-width: 100%!important;
        margin: 0;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .dropdown-item {
          padding: .5rem 1rem;
          font-size: rem-calc(14);
          &:hover {
            background-color: $color-darker;
          }
          &.active,
          &:active {
            background-color: $color-darker;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

.form-control{
    box-shadow: none!important;
    color: $color-darker;
    font-weight: 400;
    &:focus{color: $color-darker;}
}

.custom-control{
    &-inline {
        margin-right: rem-calc(32);
    }
    &-label{
        font-size: rem-calc(14);
    &::before{
    background-color: #F7F7F8;
    border-color: rgba($color-darker, .4);
    }
    .label-selected{display: none;}
}
&-input:checked ~ .custom-control-label{
    &::before{
    background-color: $color-darker;
    border-color: $color-darker;
    }
    .label-selected{display: inline;}
}
}

.custom-checkbox .custom-control-label::before {
    border-radius: rem-calc(0);
}

.custom-control-label::before,
.custom-control-label::after {
    width: 20px;
    height: 20px;
    top: 0;
    left: -1.8rem;
}

.custom-control {
    padding-left: rem-calc(30);
}

.custom-control-input {
    width: 20px;
    height: 20px;
}

.custom-checkbox.checkall .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'3'%20viewBox%3D'0%200%2010%203'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M8.9375%200H0.6875C0.300781%200%200%200.322266%200%200.6875V1.375C0%201.76172%200.300781%202.0625%200.6875%202.0625H8.9375C9.30273%202.0625%209.625%201.76172%209.625%201.375V0.6875C9.625%200.322266%209.30273%200%208.9375%200Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E");
    background-size: auto;
}

.checkbox-list {
    margin-top: rem-calc(20);
    margin-left: rem-calc(30);
    .custom-checkbox {
        margin-bottom: rem-calc(15);
    }
}

.review-item {
    .caption {
        margin-top: rem-calc(15);
        display: inline-block;
        margin-left: rem-calc(50);
        color: #fff;    
    }
}

@media (max-width:991px){
    .navbar.scrolled {
        .navtop-search .btn-search {
            color: $color-blue;
        }
    }
    .big-header.small-size {
        height: 250px;
    }
    .intro-wrap.img-overflow img {
        width: 100%;
    }
    .accordion.accordion-white {
        margin-left: 0;
        margin-top: rem-calc(30);
    }
}